$color-red: red;
$text-grey: #bab8b8;
$shiny-white: #ecf0f1;
$nav-background-dark-blue: #001529;
$grey: grey;
$nav-bar-selected-menu-background-blue: #1677ff;
$side-bar-width: 256px;
$top-nav-bar-height: 4rem;
$header-grey: #73879c;
$light-blue-text: #199be9;
$border-bottom-light: #d8d2d2;
