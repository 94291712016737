@import 'src/styles/variables.scss';

body {
    height: 100vh;
    width: 100%;
}
#root {
    height: 100%;
}

.app-container {
    display: flex;
    gap: 1rem;
    background: #f7f7f7;
    flex-direction: column;
}

.component-container {
    margin-left: $side-bar-width;
    margin-top: $top-nav-bar-height;
    padding-top: 1rem;
}
