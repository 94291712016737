.save-btn {
    background-color: #1677ff;
    color: white;
    font-size: 14px;
    padding: 4px 15px;
    border-radius: 6px;
    font-weight: 400;
    white-space: nowrap;
    border: 1px solid transparent;
    margin: 4px auto;
    text-align: center;
}
.data-entry-container {
    .ant-table-cell {
        .ant-form-item {
            margin-bottom: 0px;
        }
    }
    .election-input-box {
        .ant-form-item-control-input-content {
            display: flex;
        }
    }
    .data-entry-header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        .success-msg {
            font-size: 18px;
            color: #18b518;
            font-weight: 500;
        }
        .refresh-button {
            align-items: center;
            display: flex;
            font-weight: 600;
            color: #003eb3;
            background-color: rgb(227 219 219);
            border-color: #cac3c3;
            box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
        }
    }
}