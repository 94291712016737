.login-form {
    max-width: 300px;
}
.login-form-forgot {
    float: right;
}
.login-form-button {
    width: 100%;
}

.login-box {
    border: 1px solid grey;
    border-radius: 20px;
    box-shadow: 0 3px 10px #6185a7;
    padding: 5rem;
    min-width: 30rem;
    min-height: 25rem;
    position: absolute;
    top: 20%;
}

.forgot-password-box {
    border: 1px solid grey;
    border-radius: 20px;
    box-shadow: 0 3px 10px #6185a7;
    padding: 2rem;
    min-width: 40rem;
    position: absolute;
    top: 20%;
}

.forgot-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.client-logo {
    height: 6rem;
    width: 6rem;
    border-radius: 3rem;
    padding: 1rem;
}

.logo-container {
    position: absolute;
    left: 0;
    top: 0;
}

.login-failed {
    color: red;
    display: flex;
    width: 100%;
    justify-content: center;
}

.click-here {
    position: absolute;
    top: 60%;
    left: 5%;
    font-size: 16px;
    font-weight: bold;
}
