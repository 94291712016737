.image {
    border: 1px solid grey;
}

.profile-picture-container {
    width: 132px;
}


.uploader {
    font-size: 8px;
}
        
.form-container {
    padding: 0px 12px;
}

.update-password-container {
    width: 40%;
}