.location-align {
    display: flex;
    justify-content: space-between;
    .add-btn {
        color: #1677ff;
        text-decoration: underline;
        cursor: pointer;
    }
}
.table-list {
    display: flex;
    gap: 8px;
    .selected-row {
        background-color: #1677ff;
        color: #ffff;
    }
    :where(.css-dev-only-do-not-override-nnuwmp).ant-table-wrapper .ant-table-tbody >tr.ant-table-row:hover>td {
        background-color: #1677ff;
        color: #ffff;
    }
    .table-container {
        width: 100%;
    }
    .selected-row:hover {
        background-color: #1677ff !important;
        color: #ffff !important;
    }

}