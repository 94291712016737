@import 'src/styles/variables.scss';
.manage-permissions-container {
    margin: 1rem;
    tr th.ant-table-cell {
        background-color: #c6c8ca;
    }
}

.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

.heading {
    border-bottom: 1px solid $border-bottom-light;
    margin-bottom: 20px;
}
