@import 'src/styles/variables.scss';

.nav-bar {
    display: flex;
    width: 100%;
}

.navbar-side-container {
    position: fixed;
    left: 0;
    height: calc(100% - 60px);
    width: $side-bar-width;
    z-index: 1;
    ul li:last-child ul li:last-child{
        margin-bottom: 60px;
    }
}

.aadhan-icon {
    width: 10rem;
    margin-left: 30px;
}

.user-info-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: $nav-background-dark-blue;

    span.ant-avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $grey;
        margin-left: 30px;
    }
}

.user-details-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 10px;
    margin-bottom: 10px;
}

.user {
    display: flex;
    gap: 1rem;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid white;
}

.welcome {
    color: $text-grey;
    font-size: 13px;
    line-height: 30px;
}

.profile-info {
    font-size: 14px;
    color: $shiny-white;
    margin: 0;
    font-weight: 300;
}

.top-nav-bar {
    background-color: #001529;
    width: 100%;
    position: fixed;
    top: 0;
    height: 4rem;
    border-bottom: 1px solid black;
    z-index: 1;
    left: $side-bar-width;
}

.logged-in-user {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: absolute;
    right: 18rem;
    margin-top: 10px;

    span.ant-avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $grey;
        margin-left: 30px;
    }
}

.logged-in-user:hover .user-dropdown-content {
    cursor: pointer;
}

.user-dropdown-content {
    display: flex;
    flex-direction: column;
    position: absolute;
    min-width: 140px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 60px;
    border: 1px solid black;
    border-radius: 5px;
    background-color: $nav-background-dark-blue;

    svg {
        color: white;
    }
}

.user-dropdown-content div:hover {
    background-color: $nav-bar-selected-menu-background-blue;
}

.user-dropdown-text {
    color: $shiny-white;
}

.dropdown-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
}

.profile-arrow-container {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    color: white;
}
